import React from "react";
import sha256 from "crypto-js/sha256";
import hmacSHA512 from "crypto-js/hmac-sha512";
import Base64 from "crypto-js/enc-base64";
import styles from "./Home.module.css";
class Home extends React.Component {
	placeOrder = (position) => {
		let endpoint = "https://testnet.binancefuture.com/fapi/v1/order?";
		let postObj = {};
		postObj.side = position === 1 ? "BUY" : "SELL";
		postObj.symbol = "BTCUSDT";
		postObj.type = "MARKET";
		postObj.quantity = "0.00001";
		let queryString =
			"symbol=" +
			postObj.symbol +
			"&side=" +
			postObj.side +
			"&type=" +
			postObj.type +
			"&timeInForce=EST&quantity=" +
			postObj.quantity +
			"&recvWindow=60000&timestamp=" +
			Date.now();
		const hashDigest = sha256(queryString);
		const hmacDigest = Base64.stringify(
			hmacSHA512(hashDigest, this.state.api_secret)
		);
		postObj.signature = fetch(
			"https://clyv.in/api/" +
				encodeURIComponent(endpoint + queryString + "&signature=" + hmacDigest),
			{
				headers: {
					"X-MBX-APIKEY": this.state.api_key,
				},
			}
		);
	};
	render() {
		return (
			<div>
				<div className={styles.inputFormContainer}>
					<input
						placeholder="API KEY"
						onChange={(e) => {
							this.setState({
								api_key: e.target.value,
							});
						}}
					></input>
					<input
						placeholder="API SECRET"
						type="password"
						onChange={(e) => {
							this.setState({
								api_secret: e.target.value,
							});
						}}
					></input>
				</div>
				<div className={styles.tradeButtonContainer}>
					<button
						id={styles.long}
						className={styles.tradeButton}
						onClick={() => this.placeOrder(1)}
					>
						LONG
					</button>
					<button
						id={styles.short}
						className={styles.tradeButton}
						onClick={() => this.placeOrder(-1)}
					>
						SHORT
					</button>
				</div>
			</div>
		);
	}
}
export default Home;
